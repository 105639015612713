import React, { useState } from 'react';
import { NavigationLinks } from './navigation-links';
import classnames from 'classnames';
import axios from 'axios';
import styles from './footer.module.scss';

const REGEX = {
  VALID_EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

const isValidEmail = email => {
  return (
    REGEX.VALID_EMAIL.test(String(email).toLowerCase()) && email.length < 255
  );
};

export default () => {
  const year = new Date().getFullYear();
  const suffix = `Copyrights ©${year}: Oopsy Daisy Baby`;
  const [subscriptionEmail, setSubscriptionEmail] = useState('');
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showWarningNotification, setShowWarningNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [
    isSubscriptionButtonDisabled,
    setIsSubscriptionButtonDisabled
  ] = useState(false);
  const [
    isInvalidPasswordTagVisible,
    setIsInvalidPasswordTagVisible
  ] = useState(false);

  function onSubscriptionInputChange(event) {
    setIsInvalidPasswordTagVisible(false);

    setSubscriptionEmail(event.target.value);
  }

  function hideNotifications() {
    setShowSuccessNotification(false);
    setShowWarningNotification(false);
    setShowErrorNotification(false);
  }

  function onSubscribe() {
    if (!subscriptionEmail || !isValidEmail(subscriptionEmail)) {
      setIsInvalidPasswordTagVisible(true);
      return;
    }

    setIsSubscriptionButtonDisabled(true);

    axios
      .put(
        'https://2b99o29axl.execute-api.eu-west-2.amazonaws.com/prod/email',
        {
          email: subscriptionEmail
        }
      )
      .then(() => {
        setShowSuccessNotification(true);
        setSubscriptionEmail('');
      })
      .catch(error => {
        if (error.response.status === 409) {
          setShowWarningNotification(true);
        } else {
          setShowErrorNotification(true);
        }
      })
      .then(() => {
        setIsSubscriptionButtonDisabled(false);
        setTimeout(hideNotifications, 5000);
      });
  }

  return (
    <footer className={styles.container}>
      <div className={styles.main}>
        <div className={styles.inner}>
          <div className="columns is-vcentered">
            <div className="column is-one-quarter">
              <img
                src="/images/logo.png"
                alt="Oopsy Daisy Baby Logo"
                className={styles.logo}
              />
            </div>
            <div className="column">
              <div className={styles.newsletter}>
                <label htmlFor="email">
                  Subscribe to be first to know about events, new workshops and
                  promotions!
                </label>
                <div className={styles.newsletterInner}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Please enter your email"
                    className="input is-rounded"
                    onChange={onSubscriptionInputChange}
                    value={subscriptionEmail}
                  />
                  <button
                    className="button is-dark"
                    onClick={onSubscribe}
                    disabled={isSubscriptionButtonDisabled}
                  >
                    send
                  </button>
                  {isInvalidPasswordTagVisible && (
                    <span
                      className={classnames([
                        styles.tag,
                        'tag is-danger animated fadeIn'
                      ])}
                    >
                      please provide valid email address
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="column">
              <div className={styles.kids}>
                <div className={styles.row}>
                  <span>
                    <img src="/images/baby-basinet.jpg" alt="baby" />
                  </span>
                  <span>
                    <img src="/images/baby-on-mat.jpg" alt="baby" />
                  </span>
                  <span>
                    <img src="/images/baby-sit.jpg" alt="baby" />
                  </span>
                </div>
                <div className={styles.row}>
                  <span>
                    <img src="/images/baby-sleep-2.jpg" alt="baby" />
                  </span>
                  <span>
                    <img src="/images/baby-smile.jpg" alt="baby" />
                  </span>
                  <span>
                    <img src="/images/baby-feet.jpg" alt="baby" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.contact}>
          <NavigationLinks />
        </div>
        {showSuccessNotification && (
          <div
            className={classnames([
              styles.notification,
              'notification is-primary animated slideInUp'
            ])}
          >
            <button className="delete" onClick={hideNotifications}></button>
            You have successfully subscribed to our newsletter!
          </div>
        )}
        {showWarningNotification && (
          <div
            className={classnames([
              styles.notification,
              'notification is-warning animated slideInUp'
            ])}
          >
            <button className="delete" onClick={hideNotifications}></button>
            This email address already exists in our database!
          </div>
        )}
        {showErrorNotification && (
          <div
            className={classnames([
              styles.notification,
              'notification is-danger animated slideInUp'
            ])}
          >
            <button className="delete" onClick={hideNotifications}></button>
            Oops, something went wrong! Please try again later.
          </div>
        )}
      </div>

      <div className={styles.suffix}>{suffix}</div>
    </footer>
  );
};
