import React from 'react';
import classnames from 'classnames';
import styles from './header.module.scss';

export default () => (
  <header className={classnames([styles.container])}>
    <div
      className={classnames([
        'columns is-gapless is-multiline is-vcentered',
        styles.columns
      ])}
    >
      <div className={classnames(['column is-one-quarter', styles.logo])}>
        <img src="/images/logo.png" alt="Oopsy Daisy Baby Logo" />
      </div>
      <div className={classnames(['column is-half', styles.logotype])}>
        <h1>Oopsy Daisy Baby</h1>
        <strong>professional help and support for all new parents</strong>
      </div>
      <div className="column">
        <div className={styles.contact}>
          <div className={styles.contactDetails}>
            <span className={styles.person}>iwona.libich@gmail.com</span>
            <span className={styles.contactDetail}>+44 7508 609 703</span>
          </div>
          <span className={styles.facebookLink}>
            <a
              href="https://www.facebook.com/oopsydaisybaby.co.uk"
              target="_blank"
            >
              <img
                src="/images/facebook-button-250.jpg"
                alt="Oopsy Daisy Baby on Facebook"
              />
            </a>
          </span>
        </div>
      </div>
    </div>
  </header>
);
