import React from 'react';
import { Link } from 'gatsby';

export const NavigationLinks = () => (
  <ul>
    <li>
      <Link to="/">home</Link>
    </li>
    <li>
      <Link to="/about-me">about me</Link>
    </li>
    <li>
      <Link to="/development-in-first-year">development in 1st year</Link>
    </li>
    <li>
      <Link to="/shantala-massage">Shantala massage</Link>
    </li>
    <li>
      <Link to="/contact">contact</Link>
    </li>
  </ul>
);
