import React from 'react';
import { NavigationLinks } from './navigation-links';
import styles from './navigation.module.scss';

export const Navigation = () => (
  <div className={styles.navigation}>
    <nav>
      <NavigationLinks />
    </nav>
  </div>
);
