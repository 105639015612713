import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './layout.module.scss';
import Header from './header.js';
import { Navigation } from './navigation.js';
import Footer from './footer.js';

export default ({ children }) => (
  <div className={styles.container}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Oopsy Daisy Baby</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <Header />
    <Navigation />
    <div className={styles.content}>{children}</div>
    <Footer />
  </div>
);
